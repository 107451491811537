<div id="footer" class="hidden w-full md:flex justify-content-center">
	<div id="content" class="hidden">
		<div class="flex">
			<div>
				<p class="footer-header">
					{{ 'dashboard.footer.travels' | translate }}
				</p>

				<a href="#request-travel-ui">
					<p>{{ 'dashboard.footer.requestNewTravel' | translate }}</p>
				</a>
				<a href="#popular-routes-section">
					<p>{{ 'dashboard.footer.popularRoutes' | translate }}</p>
				</a>
				<a href="/map">
					<p>{{ 'dashboard.footer.map' | translate }}</p>
				</a>
			</div>
		</div>
		<div class="flex">
			<div>
				<p class="footer-header">
					{{ 'dashboard.footer.customerSupport' | translate }}
				</p>

				<a href="/contacts#request-travel-ui">
					<p>{{ 'dashboard.footer.help' | translate }}</p>
				</a>
				<a (click)="goToMyRequests()">
					<p>{{ 'dashboard.footer.manageTravel' | translate }}</p>
				</a>
				<a href="/report-problem">
					<p>{{ 'dashboard.footer.reportIssues' | translate }}</p>
				</a>
			</div>
		</div>
		<div class="flex">
			<div>
				<p class="footer-header">
					{{ 'dashboard.footer.termsAndConditions' | translate }}
				</p>

				<a href="/policy/faq">
					<p>{{ 'dashboard.footer.FAQ' | translate }}</p>
				</a>
				<!-- 
				<a href="/policy/privacy">
					<p>{{ 'dashboard.footer.privacyPolicy' | translate }}</p>
				</a>
				<a href="/policy/cookies">
					<p>{{ 'dashboard.footer.cookiePolicy' | translate }}</p>
				</a>
				<a href="/policy/terms">
					<p>{{ 'dashboard.footer.termsOfUse' | translate }}</p>
				</a> 
				-->
			</div>
		</div>
		<div class="flex">
			<div>
				<p class="footer-header">
					{{ 'dashboard.footer.contacts' | translate }}
				</p>
				<p>{{ 'contacts.footer.place' | translate }}</p>
				<p>
					<a
						href="tel:{{
							'contacts.footer.phoneNumber' | translate
						}}"
					>
						{{ 'contacts.footer.phoneNumber' | translate }}
					</a>
				</p>
				<p>
					<a href="mailto:transportes@cm-castelobranco.pt">
						{{ 'contacts.footer.email' | translate }}
					</a>
				</p>
			</div>
		</div>
	</div>
</div>
